import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Chip, Typography } from '@mui/material';
export const QuickQuoteChip = () => <Chip size="small" icon={<CheckCircleIcon sx={{
  fontSize: '17px',
  color: 'common.white'
}} />} color="info" sx={{
  width: '100%',
  justifyContent: 'left',
  maxWidth: 176
}} label={<Typography variant="bodySmall" sx={{
  color: 'info.contrastText'
}}>
        Quick Quote
      </Typography>} data-sentry-element="Chip" data-sentry-component="QuickQuoteChip" data-sentry-source-file="QuickQuoteChip.tsx" />;