import currency from 'currency.js';
import InfoIcon from '@mui/icons-material/Info';
import ShieldIcon from '@mui/icons-material/Shield';
import { Stack, Typography, Box, Tooltip } from '@mui/material';
import { getDiscountPercentage, formatCents } from '@qb/frontend/utils/formatCurrency';
import { PartPublicQtyAndPriceResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
export const SubTotal = ({
  price,
  qty
}: {
  price: number;
  qty: number;
}) => <Stack sx={{
  alignItems: 'end',
  minWidth: 100
}} data-sentry-element="Stack" data-sentry-component="SubTotal" data-sentry-source-file="TrustBoxAtomComponents.tsx">
    <div>
      <Typography variant="h4" component="p" align="center" sx={{
      mb: 1
    }} data-sentry-element="Typography" data-sentry-source-file="TrustBoxAtomComponents.tsx">
        {currency(price, {
        fromCents: true
      }).multiply(qty).format()}
      </Typography>
      <Typography variant="bodySmall" align="center" sx={{
      color: 'grey.500',
      fontSize: 11
    }} data-sentry-element="Typography" data-sentry-source-file="TrustBoxAtomComponents.tsx">
        {formatCents(price)} each
      </Typography>
    </div>
  </Stack>;
export const PriceFields = ({
  originalPrice,
  price,
  authUserCompanyName
}: Pick<PartPublicQtyAndPriceResponse, 'originalPrice' | 'price'> & {
  authUserCompanyName?: string;
}) => {
  const discountPercentage = getDiscountPercentage(originalPrice, price);
  const isDiscounted = !!discountPercentage;
  const priceField = !!price && <Typography variant="h2" sx={{
    mb: [0, 0, 0.5]
  }}>
      {formatCents(price)}
    </Typography>;
  if (isDiscounted) {
    return <>
        <Box sx={{
        bgcolor: 'accent.main',
        pl: 2,
        pb: 1,
        pr: 4,
        mt: 2,
        ml: -2,
        mb: 1,
        display: 'inline-block',
        borderRadius: '0 50px 50px 0'
      }}>
          {priceField}
          <Typography variant="bodySmall" sx={{
          color: 'text.primary',
          mt: -1.5
        }}>
            {authUserCompanyName} special price{' '}
            <Tooltip title="This price includes special discounts for your company.">
              <InfoIcon sx={{
              fontSize: 12,
              cursor: 'pointer',
              mb: '-2px'
            }} />
            </Tooltip>
          </Typography>
        </Box>
        {!!originalPrice && <Typography variant="bodyMedium" sx={{
        fontWeight: 600,
        mb: 4
      }}>
            Regular price:{' '}
            <Typography component="span" variant="bodyMedium" sx={{
          fontWeight: 600,
          textDecoration: 'line-through'
        }}>
              {formatCents(originalPrice)}
            </Typography>
          </Typography>}
      </>;
  }
  return priceField;
};
export const WarrantyLabel = () => <Stack sx={{
  alignItems: 'end'
}} data-sentry-element="Stack" data-sentry-component="WarrantyLabel" data-sentry-source-file="TrustBoxAtomComponents.tsx">
    <Stack direction="row" sx={{
    alignItems: 'center',
    display: 'inline-flex',
    bgcolor: 'success.12p',
    p: 0.5,
    mt: 1
  }} data-sentry-element="Stack" data-sentry-source-file="TrustBoxAtomComponents.tsx">
      <ShieldIcon sx={{
      color: 'success.main'
    }} data-sentry-element="ShieldIcon" data-sentry-source-file="TrustBoxAtomComponents.tsx" />
      <Typography variant="bodySmall" sx={{
      color: 'text.primary',
      ml: 0.5
    }} data-sentry-element="Typography" data-sentry-source-file="TrustBoxAtomComponents.tsx">
        Manufacturer warranty
      </Typography>
    </Stack>
  </Stack>;