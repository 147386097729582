'use client';

import { Box, Button, Stack, Typography } from '@mui/material';
import { MascotAnimationFriendly } from '@qb/frontend/components/OmniSearch/_components/OmniSearchDesktopPopup/components/MascotAnimation/components/MascotAnimationFriendly';
export const NoPartResults = () => {
  return <Box sx={{
    backgroundColor: 'common.backgroundColor',
    p: 2,
    mt: 2
  }} data-sentry-element="Box" data-sentry-component="NoPartResults" data-sentry-source-file="NoPartResults.tsx">
      <Stack direction={{
      xs: 'column',
      md: 'row'
    }} sx={{
      alignItems: 'center'
    }} data-sentry-element="Stack" data-sentry-source-file="NoPartResults.tsx">
        <Box sx={{
        mb: [2, 2, 0],
        ml: 2,
        mr: 2
      }} data-sentry-element="Box" data-sentry-source-file="NoPartResults.tsx">
          <Typography variant="h6" sx={{
          mb: 1
        }} data-sentry-element="Typography" data-sentry-source-file="NoPartResults.tsx">
            We haven’t found the item you were looking for.
          </Typography>
          <Typography variant="bodyMedium" data-sentry-element="Typography" data-sentry-source-file="NoPartResults.tsx">
            You can modify the search results by changing the search query or
            applying different filters.
          </Typography>
        </Box>
        <Box sx={{
        bgcolor: 'common.white',
        py: 2,
        px: 3,
        position: 'relative',
        mr: 10,
        mb: 2,
        borderRadius: '10px',
        maxWidth: 330
      }} data-sentry-element="Box" data-sentry-source-file="NoPartResults.tsx">
          <Typography variant="bodyMedium" sx={{
          mb: 2
        }} data-sentry-element="Typography" data-sentry-source-file="NoPartResults.tsx">
            {`Don't see the part you're looking for? Write to us and we will
                help you find it.`}
          </Typography>
          <Button variant="contained" color="primary" fullWidth onClick={e => {
          e.preventDefault();
          window?.salesPilotPostMessages?.openChatPage();
        }} data-sentry-element="Button" data-sentry-source-file="NoPartResults.tsx">
            Chat with us
          </Button>
          <Box sx={{
          position: 'absolute',
          right: -77,
          bottom: 12,
          width: 100,
          height: 100
        }} data-sentry-element="Box" data-sentry-source-file="NoPartResults.tsx">
            <MascotAnimationFriendly autoplay={false} data-sentry-element="MascotAnimationFriendly" data-sentry-source-file="NoPartResults.tsx" />
          </Box>
        </Box>
      </Stack>
    </Box>;
};