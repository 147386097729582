'use client';

import { useTransition } from 'react';
import dynamic from 'next/dynamic';
import { Stack } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { TenantAliasProps } from '@qb/frontend/types/tenantAlias.types';
import { ActiveFacets } from '@/global/pages/Search/_components/ActiveFacets';
import { InStockFilter } from '@/global/pages/Search/_components/InStockFilter';
import { NavigationTransitionContext } from '@/global/pages/Search/_contexts/NavigationTransitionContext';
import { SearchDataPayload, SearchDataResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
const FiltersMobile = dynamic(() => import('@/global/pages/Search/_components/FiltersMobile').then(mod => mod.FiltersMobile), {
  ssr: false
});
type ActiveFacetsAndFiltersMobileProps = TenantAliasProps & {
  searchData: SearchDataResponse;
  searchDataPayload: SearchDataPayload;
};
export const ActiveFacetsAndFiltersMobileInternal = ({
  tenantAlias,
  searchData,
  searchDataPayload
}: ActiveFacetsAndFiltersMobileProps) => {
  const [isPending, startTransition] = useTransition();
  const isMobile = useIsMobile();
  return <NavigationTransitionContext.Provider value={{
    isPending,
    startTransition
  }} data-sentry-element="unknown" data-sentry-component="ActiveFacetsAndFiltersMobileInternal" data-sentry-source-file="ActiveFacetsAndFiltersMobileInternal.tsx">
      <Stack direction="row" sx={{
      alignItems: 'center',
      justifyContent: 'space-between',
      mb: 1
    }} data-sentry-element="Stack" data-sentry-source-file="ActiveFacetsAndFiltersMobileInternal.tsx">
        <InStockFilter sx={{
        ml: [1, 0, 0]
      }} data-sentry-element="InStockFilter" data-sentry-source-file="ActiveFacetsAndFiltersMobileInternal.tsx" />
        <ActiveFacets facets={searchData.facets} manufacturerFacets={searchData.manufacturerFacets} data-sentry-element="ActiveFacets" data-sentry-source-file="ActiveFacetsAndFiltersMobileInternal.tsx" />
        {isMobile && <FiltersMobile tenantAlias={tenantAlias} facets={searchData.facets} manufacturerFacets={searchData.manufacturerFacets} searchDataPayload={searchDataPayload} />}
      </Stack>
    </NavigationTransitionContext.Provider>;
};