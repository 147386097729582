'use client';

import { Grid2, Box, Typography, Container } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { BenefitsBanner } from '@/global/pages/PartDisplayPage/_components/BenefitsBanner';
import { useRecordPartView } from '@/global/pages/PartDisplayPage/_hooks/useRecordPartView';
import { PartDisplayParams } from '@/shared/classes/SPSPageComponentParams/PartDisplayParams';
import { TenantAlias } from '@/shared/tenants-alias-map';
import { PartDisplayDataResponse, PartRelevantPartsDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
import { PartBasicInfo } from './_components/PartBasicInfo';
import { PartBreadcrumbs } from './_components/PartBreadcrumbs';
import { PartCondition } from './_components/PartCondition';
import { PartDivider } from './_components/PartDivider';
import { MemoizedPartFeatures, getHasProductDetails } from './_components/PartFeatures';
import { PartLongDescription } from './_components/PartLongDescription';
import { PartPhotoDialog } from './_components/PartPhotoDialog';
import { PartRelatedDocuments } from './_components/PartRelatedDocuments';
import { PartRelevantPartsCarousel } from './_components/PartRelevantPartsCarousel';
import { TrustBox } from './_components/TrustBox/TrustBox';
type PartDisplayPageProps = PartDisplayDataResponse & {
  canonicalUrl: string;
  relevantParts: PartRelevantPartsDataResponse['parts'];
  partDisplayParams: PartDisplayParams;
  tenantAlias: TenantAlias;
};
export const PartDisplayPage = ({
  part,
  partCategoriesToRoot,
  relevantParts,
  canonicalUrl,
  basicParametricDatas,
  featureInstances,
  documents,
  publicQtyAndPrice,
  partDisplayParams,
  tenantAlias,
  spPartPageSetting
}: PartDisplayPageProps) => {
  const isMobile = useIsMobile();
  const partCategory = partCategoriesToRoot.at(-1);
  const hasProductDetails = getHasProductDetails(featureInstances, basicParametricDatas);
  useRecordPartView(part.id);
  return <>
      <Container sx={{
      pt: {
        xs: 1.5,
        md: 3,
        minHeight: 0
      }
    }} data-sentry-element="Container" data-sentry-source-file="PartDisplayPage.tsx">
        <Grid2 container columnSpacing={[0, 0, 3]} data-sentry-element="Grid2" data-sentry-source-file="PartDisplayPage.tsx">
          <Grid2 component="section" size={{
          xs: 12,
          md: 8
        }} data-sentry-element="Grid2" data-sentry-source-file="PartDisplayPage.tsx">
            <Box sx={{
            pb: {
              xs: 1.5,
              md: 3
            },
            pt: {
              xs: 1,
              md: 0
            }
          }} data-sentry-element="Box" data-sentry-source-file="PartDisplayPage.tsx">
              <PartBreadcrumbs partID={part.id} partNumber={part.partNumber} partManufacturerName={part.manufacturer.name} partCategoriesToRoot={partCategoriesToRoot} data-sentry-element="PartBreadcrumbs" data-sentry-source-file="PartDisplayPage.tsx" />
            </Box>
            <Grid2 container columns={8} spacing={3} size={{
            xs: 12
          }} sx={{
            mb: 1
          }} data-sentry-element="Grid2" data-sentry-source-file="PartDisplayPage.tsx">
              <Grid2 size={{
              xs: 8
            }} sx={{
              py: 1
            }} data-sentry-element="Grid2" data-sentry-source-file="PartDisplayPage.tsx">
                <Typography variant="h2" component="h1" sx={{
                fontSize: [26, 36],
                lineHeight: 1.3,
                mt: [1, 1, 0]
              }} data-sentry-element="Typography" data-sentry-source-file="PartDisplayPage.tsx">
                  {part.partNumber} {part.manufacturer.name}{' '}
                  {partCategory?.name}
                </Typography>
              </Grid2>
              <Grid2 size={{
              xs: 8,
              md: 3
            }} data-sentry-element="Grid2" data-sentry-source-file="PartDisplayPage.tsx">
                <PartPhotoDialog part={part} tenantAlias={tenantAlias} data-sentry-element="PartPhotoDialog" data-sentry-source-file="PartDisplayPage.tsx" />
              </Grid2>
              <Grid2 size={{
              xs: 8,
              md: 5
            }} sx={{
              pt: [0, 0, 1.5],
              display: 'flex',
              flexDirection: 'column'
            }} data-sentry-element="Grid2" data-sentry-source-file="PartDisplayPage.tsx">
                <PartBasicInfo partCategoriesToRoot={partCategoriesToRoot} part={part} publicQtyAndPrice={publicQtyAndPrice} partDisplayParams={partDisplayParams} spPartPageSetting={spPartPageSetting} data-sentry-element="PartBasicInfo" data-sentry-source-file="PartDisplayPage.tsx" />
              </Grid2>
            </Grid2>
            {hasProductDetails && <>
                <MemoizedPartFeatures featureInstances={featureInstances} basicParametricDatas={basicParametricDatas} partAbsoluteUrl={canonicalUrl} />
                <PartDivider />
              </>}
            <PartCondition data-sentry-element="PartCondition" data-sentry-source-file="PartDisplayPage.tsx" />
            <PartDivider data-sentry-element="PartDivider" data-sentry-source-file="PartDisplayPage.tsx" />
            {part.longDescription?.length > 0 && <>
                <PartLongDescription longDescription={part.longDescription} partNumber={part.partNumber} />
                <PartDivider />
              </>}
            <PartRelatedDocuments part={part} documents={documents} data-sentry-element="PartRelatedDocuments" data-sentry-source-file="PartDisplayPage.tsx" />
          </Grid2>
          {!isMobile && <Grid2 data-cy="StickySidebar" component="section" size={{
          xs: 12,
          md: 4
        }}>
              <TrustBox partDisplayParams={partDisplayParams} part={part} publicQtyAndPrice={publicQtyAndPrice} spPartPageSetting={spPartPageSetting} />
            </Grid2>}
        </Grid2>
      </Container>
      {relevantParts.length > 0 && <PartRelevantPartsCarousel parts={relevantParts} partAbsoluteUrl={canonicalUrl} tenantAlias={tenantAlias} />}
      <BenefitsBanner data-sentry-element="BenefitsBanner" data-sentry-source-file="PartDisplayPage.tsx" />
    </>;
};