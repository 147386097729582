'use client';

import { useTransition } from 'react';
import { ShowOnBreakpointUp } from '@qb/frontend/components/ShowOnBreakpoint';
import { Facets } from '@/global/pages/Search/_components/Facets';
import { InStockFilter } from '@/global/pages/Search/_components/InStockFilter';
import { ManufacturerFilter } from '@/global/pages/Search/_components/ManufacturerFilter';
import { NavigationTransitionContext } from '@/global/pages/Search/_contexts/NavigationTransitionContext';
import { SearchDataResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
import { isNonEmptyArray } from '@/shared/types/util/TypeGuards';
type FiltersInternalProps = {
  manufacturerFacets: SearchDataResponse['manufacturerFacets'];
  facets?: SearchDataResponse['facets'];
};
export const FiltersInternal = ({
  manufacturerFacets,
  facets
}: FiltersInternalProps) => {
  const [isPending, startTransition] = useTransition();
  return <NavigationTransitionContext.Provider value={{
    isPending,
    startTransition
  }} data-sentry-element="unknown" data-sentry-component="FiltersInternal" data-sentry-source-file="FiltersInternal.tsx">
      <ShowOnBreakpointUp breakpoint="md" data-sentry-element="ShowOnBreakpointUp" data-sentry-source-file="FiltersInternal.tsx">
        <InStockFilter sx={{
        mb: 2,
        ml: 2
      }} data-sentry-element="InStockFilter" data-sentry-source-file="FiltersInternal.tsx" />
      </ShowOnBreakpointUp>
      <ManufacturerFilter manufacturerFacets={manufacturerFacets} data-sentry-element="ManufacturerFilter" data-sentry-source-file="FiltersInternal.tsx" />
      {isNonEmptyArray(facets) && <Facets facets={facets} />}
    </NavigationTransitionContext.Provider>;
};