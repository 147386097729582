'use client';

import { useState, useEffect, PropsWithChildren } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Stack, Typography, Box, CircularProgress, SvgIcon } from '@mui/material';
import { NumberInput } from '@qb/frontend/components/NumberInput/NumberInput';
import { WarrantyLabel, PriceFields, SubTotal } from '@qb/frontend/components/TrustBoxAtomComponents/TrustBoxAtomComponents';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { ReactComponent as IconParcel } from '@qb/frontend/icons/icon-parcel.svg';
import { useRequestPartPriceFromSPW } from '@/hooks/useRequestPartPriceFromSPW';
import { Widget } from '@/shared/Constants';
import { PartDisplayDataResponse, PartPublicQtyAndPriceResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
import { CallToActionAddToCart } from './CallToActionAddToCart';
import { CallToActionRequestQuote } from './CallToActionRequestQuote';
type TrustBoxPriceDetailsProps = Pick<PartDisplayDataResponse, 'part' | 'publicQtyAndPrice' | 'spPartPageSetting'>;
export const TrustBoxPriceDetails = ({
  part,
  publicQtyAndPrice,
  spPartPageSetting
}: TrustBoxPriceDetailsProps) => {
  const {
    authUser,
    requestMultiplePartsPrices,
    getPartPrice
  } = useRequestPartPriceFromSPW();
  const authUserCompanyName = authUser?.company?.name;
  useEffect(() => {
    /**
     * Request the prices for the PDP part.
     */
    requestMultiplePartsPrices([part.id]);
  }, [part.id, requestMultiplePartsPrices]);
  const {
    onHandQty,
    manufacturerStock,
    price,
    originalPrice,
    shipProcessingDays
  } = {
    ...publicQtyAndPrice,
    ...getPartPrice(part.id)
  };
  const combinedStock = onHandQty + manufacturerStock;
  const isMarketablePart = combinedStock > 0 && !!price;
  const isNoPriceNoAvailability = combinedStock === 0 && !price;
  const isMobile = useIsMobile();
  const [qty, setQty] = useState(1);
  const numberInput = <Stack direction={isNoPriceNoAvailability ? 'row' : 'column'} sx={{
    gap: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }}>
      {!isMobile && <Typography variant="bodyMediumPrimary" sx={{
      color: 'text.primary'
    }}>
          Select Qty:
        </Typography>}
      <NumberInput onValueChange={setQty} initialValue={qty} />
    </Stack>;
  if (isMarketablePart) {
    const ctaButtons = !!price && <CallToActionAddToCart part={part} price={price} qty={qty} stock={combinedStock} />;
    if (isMobile) {
      return <>
          <ShipWithinLabel onHandQty={onHandQty} manufacturerStock={manufacturerStock} shipProcessingDays={shipProcessingDays} />
          <PriceFields authUserCompanyName={authUserCompanyName} price={price} originalPrice={originalPrice} />
          <MobileCallToActionBar>
            {numberInput}
            {ctaButtons}
          </MobileCallToActionBar>
        </>;
    }
    return <>
        <ShipWithinLabel onHandQty={onHandQty} manufacturerStock={manufacturerStock} shipProcessingDays={shipProcessingDays} />
        <WarrantyLabel />
        <Box sx={{
        px: 2,
        pb: 2
      }}>
          <PriceFields authUserCompanyName={authUserCompanyName} price={price} originalPrice={originalPrice} />
          <Stack direction="row" sx={{
          justifyContent: 'space-between',
          alignItems: 'end',
          mb: 3
        }}>
            <StockLabel stock={combinedStock} />
            {numberInput}
            <SubTotal qty={qty} price={price} />
          </Stack>
          {ctaButtons}
        </Box>
      </>;
  }

  // No stock info (with a price):
  if (combinedStock === 0 && !!price) {
    // console.log('No stock info:');

    const header = <>
        {!!spPartPageSetting?.primaryMessage && <Typography variant="bodyMedium" sx={{
        mb: 2
      }}>
            {spPartPageSetting?.primaryMessage}
          </Typography>}
      </>;
    if (isMobile) {
      return <>
          <PriceFields authUserCompanyName={authUserCompanyName} price={price} originalPrice={originalPrice} />
          {header}
          <MobileCallToActionBar>
            {numberInput}
            <CallToActionRequestQuote spPartPageSetting={spPartPageSetting} shouldUseMessageFromSettings={true} price={price} part={part} qty={qty} />
          </MobileCallToActionBar>
        </>;
    }
    return <>
        <WarrantyLabel />
        <Box sx={{
        pl: 2,
        pr: 2,
        pb: 2
      }}>
          <PriceFields authUserCompanyName={authUserCompanyName} price={price} originalPrice={originalPrice} />
          {header}
          <Stack direction="row" sx={{
          justifyContent: 'space-between',
          mb: 3
        }}>
            {numberInput}
            <SubTotal qty={qty} price={price} />
          </Stack>
          <CallToActionRequestQuote spPartPageSetting={spPartPageSetting} shouldUseMessageFromSettings={true} price={price} part={part} qty={qty} />
        </Box>
      </>;
  }

  // No price:
  if (combinedStock > 0) {
    // console.log('No price:');

    const header = <>
        <Typography variant="h4" component="p" color="secondary" sx={{
        mb: 1,
        mt: 1
      }}>
          Get a quick Quote!
        </Typography>
        <Typography variant="bodyMedium" sx={{
        mb: 2
      }}>
          Request a quote, and we will send you price within minutes.
        </Typography>
      </>;
    if (isMobile) {
      return <>
          {header}
          <MobileCallToActionBar>
            {numberInput}
            <CallToActionRequestQuote spPartPageSetting={spPartPageSetting} shouldUseMessageFromSettings={false} price={price} part={part} qty={qty} />
          </MobileCallToActionBar>
        </>;
    }
    return <>
        <WarrantyLabel />
        <Box sx={{
        pl: 2,
        pr: 2,
        pb: 2
      }}>
          {header}
          <Stack direction="row" sx={{
          justifyContent: 'space-between',
          mb: 3
        }}>
            <StockLabel stock={combinedStock} />
            {numberInput}
          </Stack>
          <CallToActionRequestQuote spPartPageSetting={spPartPageSetting} shouldUseMessageFromSettings={false} price={price} part={part} qty={qty} />
        </Box>
      </>;
  }

  // No price - no availability:
  // console.log('No price - no availability:');

  const header = <>
      <Typography variant="h4" component="p" color="secondary" sx={{
      mb: 1,
      mt: 1
    }}>
        Get a quick Quote!
      </Typography>
      <Typography variant="bodyMedium" sx={{
      mb: 2
    }}>
        Request a quote, and we will send you price and availability within
        minutes.
      </Typography>
    </>;
  if (isMobile) {
    return <>
        {header}
        <MobileCallToActionBar>
          {numberInput}
          <CallToActionRequestQuote spPartPageSetting={spPartPageSetting} shouldUseMessageFromSettings={false} price={price} part={part} qty={qty} />
        </MobileCallToActionBar>
      </>;
  }
  return <>
      <WarrantyLabel data-sentry-element="WarrantyLabel" data-sentry-source-file="TrustBoxPriceDetails.tsx" />
      <Box sx={{
      pl: 2,
      pr: 2,
      pb: 2
    }} data-sentry-element="Box" data-sentry-source-file="TrustBoxPriceDetails.tsx">
        {header}
        <Stack direction="row" sx={{
        justifyContent: 'space-between',
        mb: 3
      }} data-sentry-element="Stack" data-sentry-source-file="TrustBoxPriceDetails.tsx">
          {numberInput}
        </Stack>
        <CallToActionRequestQuote spPartPageSetting={spPartPageSetting} shouldUseMessageFromSettings={false} price={price} part={part} qty={qty} data-sentry-element="CallToActionRequestQuote" data-sentry-source-file="TrustBoxPriceDetails.tsx" />
      </Box>
    </>;
};
const MobileCallToActionBar = ({
  children
}: PropsWithChildren) => <Stack sx={{
  display: 'grid',
  columnGap: 5,
  gridTemplateColumns: 'auto 1fr',
  gridTemplateRows: 'auto auto',
  bgcolor: 'common.backgroundColor',
  position: 'fixed',
  bottom: Widget.VERTICAL_STYLE_NAVBAR_HEIGHT,
  left: 0,
  right: 0,
  zIndex: 'appBar',
  py: 1,
  px: 3,
  boxShadow: 2
}} data-sentry-element="Stack" data-sentry-component="MobileCallToActionBar" data-sentry-source-file="TrustBoxPriceDetails.tsx">
    {children}
  </Stack>;
const ShipWithinLabel = ({
  onHandQty,
  manufacturerStock,
  shipProcessingDays
}: Pick<PartPublicQtyAndPriceResponse, 'shipProcessingDays' | 'onHandQty' | 'manufacturerStock'>) => {
  // Default value
  let text = `This item can ship within ${shipProcessingDays} day${shipProcessingDays > 1 ? 's' : ''}!`;
  if (shipProcessingDays === 0 && onHandQty) {
    text = 'This item can ship within 24h!';
  } else if (!onHandQty && manufacturerStock) {
    text = 'This item can ship in 3-5 days!';
  }
  return <Stack direction={['row', 'row', 'row-reverse']} sx={{
    alignItems: 'center',
    bgcolor: 'success.12p',
    borderRightStyle: 'solid',
    borderRightWidth: [0, 0, 8],
    borderRightColor: 'success.main',
    py: [1, 1, 1.5],
    pr: 1.5,
    pl: [0.5, 0.5, 1.5],
    mb: [1, 1, 0],
    width: ['fit-content', 'fit-content', '100%']
  }} data-sentry-element="Stack" data-sentry-component="ShipWithinLabel" data-sentry-source-file="TrustBoxPriceDetails.tsx">
      <SvgIcon component={IconParcel} inheritViewBox sx={{
      color: 'success.main',
      fontSize: 32
    }} data-sentry-element="SvgIcon" data-sentry-source-file="TrustBoxPriceDetails.tsx" />
      <Typography variant="h6" component="p" sx={{
      mr: [0, 0, 1.5],
      ml: [1, 1, 0]
    }} data-sentry-element="Typography" data-sentry-source-file="TrustBoxPriceDetails.tsx">
        {text}
      </Typography>
    </Stack>;
};
const StockLabel = ({
  stock
}: {
  stock: number;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 3000);
  }, []);
  const verifyingLabel = isLoading ? 'Stock: Verifying...' : 'Stock:';
  return <Stack data-sentry-element="Stack" data-sentry-component="StockLabel" data-sentry-source-file="TrustBoxPriceDetails.tsx">
      <Typography variant="bodyMediumPrimary" sx={{
      color: 'text.primary'
    }} data-sentry-element="Typography" data-sentry-source-file="TrustBoxPriceDetails.tsx">
        {verifyingLabel}
      </Typography>
      <Stack direction="row" sx={{
      alignItems: 'center',
      display: 'inline-flex',
      transition: 'all 0.5s ease',
      bgcolor: isLoading ? 'common.white' : 'success.12p',
      py: 0.5,
      px: 1,
      pr: 2,
      mt: 1
    }} data-sentry-element="Stack" data-sentry-source-file="TrustBoxPriceDetails.tsx">
        {isLoading ? <CircularProgress size={24} sx={{
        color: 'text.primary'
      }} /> : <>
            <CheckCircleIcon sx={{
          color: 'success.main'
        }} />
            <Typography variant="bodyLargePrimary" sx={{
          color: 'text.primary',
          ml: 0.75
        }}>
              {stock}
            </Typography>
          </>}
      </Stack>
    </Stack>;
};