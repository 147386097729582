'use client';

import { useTransition } from 'react';
import { ActiveFacets } from '@/global/pages/Search/_components/ActiveFacets';
import { NavigationTransitionContext } from '@/global/pages/Search/_contexts/NavigationTransitionContext';
import { SearchDataResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
type ActiveFacetsWithTransitionContextInternalProps = {
  searchData: SearchDataResponse;
};
export const ActiveFacetsWithTransitionContextInternal = ({
  searchData
}: ActiveFacetsWithTransitionContextInternalProps) => {
  const [isPending, startTransition] = useTransition();
  return <NavigationTransitionContext.Provider value={{
    isPending,
    startTransition
  }} data-sentry-element="unknown" data-sentry-component="ActiveFacetsWithTransitionContextInternal" data-sentry-source-file="ActiveFacetsWithTransitionContextInternal.tsx">
      <ActiveFacets facets={searchData.facets} manufacturerFacets={searchData.manufacturerFacets} data-sentry-element="ActiveFacets" data-sentry-source-file="ActiveFacetsWithTransitionContextInternal.tsx" />
    </NavigationTransitionContext.Provider>;
};