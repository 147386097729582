import { ChangeEvent, useEffect, useState } from 'react';
import { Checkbox, Divider, FormControlLabel, List, ListItem, Typography } from '@mui/material';
import { FilterAccordion } from '@/global/pages/Search/_components/FilterAccordion';
import { useNavigationTransitionContext } from '@/global/pages/Search/_contexts/NavigationTransitionContext';
import { useUrlSearchParam } from '@/global/pages/Search/_hooks/useUrlSearchParam';
import { SearchDataResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
import { isNullableOrEmptyArray } from '@/shared/types/util/TypeGuards';
type FacetsProps = {
  facets: SearchDataResponse['facets'];
};
type FacetOptionProps = {
  facet: SearchDataResponse['facets'][0];
  facetOption: SearchDataResponse['facets'][0]['options'][0];
};
const FacetOption = ({
  facetOption,
  facet
}: FacetOptionProps) => {
  const {
    updateSearchParam,
    searchParamValues
  } = useUrlSearchParam(facet.camelCaseName);
  const initialChecked = searchParamValues.includes(facetOption.value);
  const [isChecked, setIsChecked] = useState(initialChecked);
  const {
    isPending
  } = useNavigationTransitionContext();

  // Need to sync the local checkbox state with the URL search param in case the filter gets removed from somewhere else
  useEffect(() => {
    setIsChecked(initialChecked);
  }, [initialChecked]);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked;
    setIsChecked(newChecked);
    const newFacetValues = newChecked ? [...searchParamValues, facetOption.value] : searchParamValues.filter(currentFacetValue => currentFacetValue !== facetOption.value);
    updateSearchParam(newFacetValues);
  };
  return <ListItem sx={{
    p: 0
  }} data-sentry-element="ListItem" data-sentry-component="FacetOption" data-sentry-source-file="Facets.tsx">
      <FormControlLabel control={<Checkbox size="small" checked={isChecked} onChange={handleChange} disabled={isPending} />} label={<Typography variant="bodySmall" sx={{
      color: 'text.primary'
    }}>
            {facetOption.value} ({facetOption.numParts})
          </Typography>} data-sentry-element="FormControlLabel" data-sentry-source-file="Facets.tsx" />
    </ListItem>;
};
type FacetGroupProps = {
  facet: SearchDataResponse['facets'][0];
};
const FacetGroup = ({
  facet
}: FacetGroupProps) => {
  const {
    searchParamValues
  } = useUrlSearchParam(facet.camelCaseName);
  if (isNullableOrEmptyArray(facet.options)) {
    return null;
  }
  return <>
      <Divider sx={{
      mb: 1
    }} data-sentry-element="Divider" data-sentry-source-file="Facets.tsx" />
      <FilterAccordion title={facet.name} defaultExpanded={searchParamValues.length > 0} data-sentry-element="FilterAccordion" data-sentry-source-file="Facets.tsx">
        <List data-sentry-element="List" data-sentry-source-file="Facets.tsx">
          {facet.options.map(option => <FacetOption key={option.value} facetOption={option} facet={facet} />)}
        </List>
      </FilterAccordion>
    </>;
};
export const Facets = ({
  facets
}: FacetsProps) => <>
    {facets.map(facet => <FacetGroup key={facet.name} facet={facet} />)}
  </>;