'use client';

import { useRouter } from 'next/navigation';
import { BoxProps } from '@mui/material';
import { HeroOmniSearch } from '@qb/frontend/components/OmniSearch/HeroOmniSearch';
import { TenantAliasProps } from '@qb/frontend/types/tenantAlias.types';
type SearchAndChatOmniSearchProps = TenantAliasProps & {
  width: BoxProps['width'];
};
export const SearchAndChatOmniSearch = ({
  tenantAlias,
  width
}: SearchAndChatOmniSearchProps) => {
  const router = useRouter();
  return <HeroOmniSearch searchInputName="SPSSearchAndChat" width={width} inputSx={{
    pl: 0.25,
    height: 52,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'primary.main'
    },
    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
      fontSize: 41,
      color: 'tertiary.main'
    }
  }} magicIconSx={{
    fontSize: 34,
    p: 0.6,
    m: 0.35,
    bgcolor: 'primary.main'
  }} tenantAlias={tenantAlias} handlerRedirect={href => router.push(href)} isPlaceholderAnimationEnabled={true} data-sentry-element="HeroOmniSearch" data-sentry-component="SearchAndChatOmniSearch" data-sentry-source-file="SearchAndChatOmniSearch.tsx" />;
};