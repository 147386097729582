import { useCallback, useMemo } from 'react';
import {
  useParams,
  usePathname,
  useRouter,
  useSearchParams,
} from 'next/navigation';
import { useNavigationTransitionContext } from '@/global/pages/Search/_contexts/NavigationTransitionContext';
import { createNewSearchParams } from '@/global/pages/Search/_hooks/useUrlSearchParam';
import {
  getSPSCategoryManufacturerURL,
  getSPSCategoryURL,
} from '@/global/utils/spsURLs';
import { SearchDataResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
import { isString } from '@/shared/types/util/TypeGuards';

const PARAM_NAME = 'manufacturerIDsFilter';
export const useManufacturerParam = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const params = useParams();
  const pathname = usePathname();
  const { startTransition } = useNavigationTransitionContext();

  const searchParamValues = useMemo(
    () => searchParams?.getAll(PARAM_NAME) || [],
    [searchParams],
  );

  const isChecked = ({ id }: SearchDataResponse['manufacturerFacets'][0]) =>
    searchParamValues.includes(id.toString()) ||
    params?.manufacturerID === id.toString();

  const updateManufacturerParam = useCallback(
    (
      manufacturerFacet: SearchDataResponse['manufacturerFacets'][0],
      checked: boolean,
    ) => {
      const { id, name: manufacturerName } = manufacturerFacet;
      const facetID = id.toString();
      const manufacturerID =
        params?.manufacturerID && isString(params.manufacturerID)
          ? params.manufacturerID
          : '';

      const categoryID =
        params?.categoryID && isString(params.categoryID)
          ? Number(params.categoryID)
          : null;

      const categoryName =
        params?.categoryName && isString(params.categoryName)
          ? params.categoryName
          : '';

      // Manufacturer ids can come either from search params or from the URL
      const currentManufacturerIDsFilter = searchParamValues.length
        ? searchParamValues
        : [manufacturerID].filter(Boolean);

      const newManufacturerIDsFilter = checked
        ? [...currentManufacturerIDsFilter, facetID]
        : currentManufacturerIDsFilter.filter((id) => id !== facetID);

      // If no category is selected, just use search params filters for the current URL
      if (!categoryID) {
        const newParams = createNewSearchParams(
          searchParams,
          PARAM_NAME,
          newManufacturerIDsFilter,
        );

        // We want to reset the page to 1 when a new filter is applied
        newParams.delete('page');

        const url = `${pathname ?? '/search'}?${newParams.toString()}`;

        startTransition(() => router.push(url, { scroll: false }));
        return;
      }

      const category = {
        id: categoryID,
        name: categoryName,
      };
      // If only one manufacturer is selected, redirect to the category + manufacturer page
      if (newManufacturerIDsFilter.length === 1) {
        const manufacturer = {
          id: Number(newManufacturerIDsFilter[0]),
          name: manufacturerName,
        };

        let url = getSPSCategoryManufacturerURL({ category, manufacturer });
        if (searchParams && searchParams.size > 0) {
          const newParams = createNewSearchParams(searchParams, PARAM_NAME, []);

          // We want to reset the page to 1 when a new filter is applied
          newParams.delete('page');

          url += '?' + newParams.toString();
        }

        startTransition(() => router.push(url, { scroll: false }));
        //  If more than one manufacturer is selected, redirect to the category page with the selected manufacturers
      } else {
        const newParams = createNewSearchParams(
          searchParams,
          PARAM_NAME,
          newManufacturerIDsFilter,
        );

        // We want to reset the page to 1 when a new filter is applied
        newParams.delete('page');

        const url = `${getSPSCategoryURL(category)}?${newParams.toString()}`;
        startTransition(() => router.push(url, { scroll: false }));
      }
    },
    [
      params,
      searchParams,
      router,
      searchParamValues,
      pathname,
      startTransition,
    ],
  );

  return { updateManufacturerParam, isChecked };
};
