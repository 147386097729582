import { forwardRef, useCallback } from 'react';
import { LinkProps as NextLinkProps } from 'next/dist/client/link';
import { Inter } from 'next/font/google';
import NextLink from 'next/link';
import { colors, CssVarsThemeOptions } from '@mui/material';
import { addSharpCornersStyle, addTransitionShorthand, addXXLBreakpoint, changeDefaultButtonsHeight, changeTheDefaultProps, changeTheFontFamily, generateBreakpointsCssVars, mergeCssVarsThemeOptions, resetSearchInputStyle } from '@qb/base-theme/base-theme-utils';
import BlockedOnDemoLink from '@qb/frontend/components/BlockedOnDemoLink';
import { EVENT_ROUTE_CHANGE_START } from '@qb/frontend/components/OmniSearch/omniSearchConstants';
import { EventEmitter } from '@qb/frontend/utils/EventEmitter';
import { isProdEnv } from '@qb/frontend/utils/env.utils';
import { MessagesToSP } from '@/shared/SalesPilotConstants';
const addCustomTypography = (): CssVarsThemeOptions => {
  const headingBase = {
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 1.3,
    letterSpacing: '0.15px',
    color: 'var(--mui-palette-text-primary)'
  };
  const bodyBase = {
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 1.2,
    letterSpacing: '0.15px',
    color: 'var(--mui-palette-text-secondary)'
  };
  const bodyBasePrimary = {
    ...bodyBase,
    fontWeight: 600
  };
  return {
    components: {
      MuiTypography: {
        defaultProps: {
          variant: 'bodyLarge',
          variantMapping: {
            paragraph: 'p',
            bodyLarge: 'p',
            bodyMedium: 'p',
            bodySmall: 'p',
            bodyLargePrimary: 'p',
            bodyMediumPrimary: 'p',
            bodySmallPrimary: 'p',
            bodySmallError: 'p'
          }
        }
      }
    },
    typography: {
      body1: undefined,
      body2: undefined,
      button: {
        textTransform: 'none',
        fontSize: '14px'
      },
      // headers
      h1: {
        ...headingBase,
        fontSize: 48,
        letterSpacing: 0,
        lineHeight: 1.5
      },
      h2: {
        ...headingBase,
        fontSize: 36,
        letterSpacing: 0,
        lineHeight: 1.5
      },
      h3: {
        ...headingBase,
        fontSize: 26,
        letterSpacing: 0
      },
      h4: {
        ...headingBase,
        fontSize: 20
      },
      h5: {
        ...headingBase,
        fontSize: 16
      },
      h6: {
        ...headingBase,
        fontSize: 14
      },
      // body
      bodyLarge: {
        ...bodyBase,
        fontSize: 16
      },
      bodyMedium: {
        ...bodyBase,
        fontSize: 14
      },
      bodySmall: {
        ...bodyBase,
        fontSize: 12
      },
      // body primary
      bodyLargePrimary: {
        ...bodyBasePrimary,
        fontSize: 16
      },
      bodyMediumPrimary: {
        ...bodyBasePrimary,
        fontSize: 14
      },
      bodySmallPrimary: {
        ...bodyBasePrimary,
        fontSize: 12
      },
      bodySmallError: {
        ...bodyBase,
        fontSize: 12,
        color: 'var(--mui-palette-error-main)'
      },
      paragraph: {
        ...bodyBase,
        fontSize: 14,
        lineHeight: 1.5,
        color: 'var(--mui-palette-text-primary)'
      }
    }
  };
};
const customPalette: CssVarsThemeOptions = {
  colorSchemes: {
    light: {
      palette: {
        text: {
          primary: '#161519',
          secondary: '#696974',
          disabled: '#72727E'
        },
        primaryShared: {
          main: '#1100CC',
          dark: '#0E00A3',
          light: '#4C5BDB',
          '50p': '#98AAEC',
          '30p': '#98AAEC',
          '12p': '#DCE3F9',
          '8p': '#EDF0FC',
          '4p': '#F5F7FF',
          contrastText: '#fff'
        },
        primary: {
          main: '#1100CC',
          dark: '#0E00A3',
          light: '#4C5BDB',
          '50p': '#98AAEC',
          '30p': '#98AAEC',
          '12p': '#DCE3F9',
          '8p': '#EDF0FC',
          '4p': '#F5F7FF',
          contrastText: '#fff'
        },
        secondary: {
          main: '#7200CC',
          dark: '#5B00A3',
          light: '#9259EF',
          '50p': '#B08BFF',
          '30p': '#D5C1FF',
          '12p': '#E1D2FF',
          '8p': '#EBE4FF',
          '4p': '#F2EDFF'
        },
        tertiary: {
          main: '#696974',
          dark: '#161519',
          light: '#95959E',
          '50p': '#797986',
          '30p': '#AFAFB6',
          '12p': '#DFDFE2',
          '8p': '#EAEAEC',
          '4p': '#F5F5F5',
          contrastText: '#fff'
        },
        error: {
          main: '#D32F2F',
          dark: '#C62828',
          light: '#EF5350',
          '50p': '#e99797',
          '30p': '#f1c0c0',
          '12p': '#f9e6e6',
          '8p': '#fbeeee',
          '4p': '#fdf6f6'
        },
        warning: {
          main: '#FF9800',
          dark: '#ED6C02',
          light: '#FF9800',
          '50p': '#ffcb7f',
          '30p': '#ffe0b2',
          '12p': '#fff2e0',
          '8p': '#fff6ea',
          '4p': '#fffaf4',
          contrastText: '#fff'
        },
        success: {
          main: '#1C8259',
          dark: '#156645',
          light: '#6CE0B1',
          '50p': '#8dc0ac',
          '30p': '#bad9cd',
          '12p': '#e3f0eb',
          '8p': '#ecf5f1',
          '4p': '#f5faf8',
          contrastText: '#fff'
        },
        info: {
          main: '#0288d1',
          dark: '#01579b',
          light: '#03a9f4',
          '50p': '#80c3e8',
          '30p': '#b3dbf1',
          '12p': '#e0f0f9',
          '8p': '#eaf5fb',
          '4p': '#f4fafd'
        },
        accent: {
          main: '#FFDA00',
          dark: '#F0BC00',
          light: '#FFF58C',
          '50p': '#FCE569',
          '30p': '#FDEB8E',
          '12p': '#FEF3BA',
          '8p': '#FEFAE3',
          '4p': '#FFFCED',
          contrastText: colors.common.white
        },
        common: {
          // In Figma it's Others/backgroundColor
          backgroundColor: '#F4F6F9',
          editMode: '#FEFAE3'
        }
      }
    }
  }
};
const customDefaultProps: CssVarsThemeOptions = {
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl'
      },
      styleOverrides: {
        root: {
          paddingRight: 16,
          paddingLeft: 16,
          // TODO: use theme breakpoints
          '@media (min-width: 600px)': {
            paddingRight: 16,
            paddingLeft: 16
          },
          // TODO: use theme breakpoints
          '@media (min-width: 900px)': {
            paddingRight: 48,
            paddingLeft: 48
          }
        }
      }
    },
    MuiLink: {
      defaultProps: {
        color: 'primaryShared.main'
      }
    },
    MuiButton: {
      defaultProps: {
        color: 'primaryShared'
      }
    },
    MuiSwitch: {
      defaultProps: {
        color: 'primaryShared'
      }
    },
    MuiRadio: {
      defaultProps: {
        color: 'primaryShared'
      }
    },
    MuiTextField: {
      defaultProps: {
        color: 'primaryShared'
      }
    }
  }
};
const isBlockedURLInDemo = (href: NextLinkProps['href']) =>
// Navbar
href === '/services' || href === '/about' || href === '/careers' || href === '/contact' || href === '/contact' ||
// 3 link component at bottom of home page
href === '/our-parts' || href === '/value-added' || href === '/our-parts' ||
// Search
isProdEnv && href.toString().startsWith('/search');
const isInternalLink = (href: NextLinkProps['href']) => {
  return href.toString().startsWith('/');
};
export const BlockedOnDemoLinkBehavior = forwardRef<HTMLAnchorElement, NextLinkProps>(function LinkBehaviour(props, ref) {
  const onInternalLinkClick = useCallback<NonNullable<NextLinkProps['onClick']>>(e => {
    EventEmitter.publish(EVENT_ROUTE_CHANGE_START);
    if (props.onClick) {
      props.onClick(e);
    }
  }, [props]);
  return <BlockedOnDemoLink {...props} shouldShowBlockedTooltip={isBlockedURLInDemo(props.href)} onClick={isInternalLink(props.href) ? onInternalLinkClick : props.onClick} ref={ref} />;
});
export const DefaultLinkBehaviour = forwardRef<HTMLAnchorElement, NextLinkProps>(function LinkBehaviour(props, ref) {
  if (props?.href?.toString().trim() === MessagesToSP.OPEN_CHAT_PAGE) {
    return <NextLink ref={ref} {...props} href="#" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      window?.salesPilotPostMessages?.openChatPage();
    }} />;
  }
  return <NextLink ref={ref} {...props} />;
});
const inter = Inter({
  subsets: ['latin']
});
const fontFamily = `${inter.style.fontFamily},Helvetica Neue,Helvetica,Arial,sans-serif`;
export const globalThemeOptions = mergeCssVarsThemeOptions(addSharpCornersStyle, changeTheDefaultProps, customDefaultProps, changeDefaultButtonsHeight, addTransitionShorthand, resetSearchInputStyle(), customPalette, addCustomTypography(), changeTheFontFamily(fontFamily), generateBreakpointsCssVars(), addXXLBreakpoint() // ! not sure why I had to add it again
);