import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { minutesToMilliseconds } from 'date-fns';
import { useRequestPartPriceFromSPW } from '@qb/app-sales-pilot-sites/src/hooks/useRequestPartPriceFromSPW';
import { getHostFromWindow } from '@qb/frontend/utils/hosts/getHostFromWindow';
import { spsClientQueries } from '@qb/httpRequest/spsClientQueries';
import { TenantAlias } from '@/shared/tenants-alias-map';

export const usePartsAutocompleteRequest = (
  query: string,
  tenantAlias: TenantAlias,
) => {
  const { requestMultiplePartsPrices, getPartPrice } =
    useRequestPartPriceFromSPW();

  const { isLoading: arePartsLoading, data: parts = [] } = useQuery({
    ...spsClientQueries.search.partAutocomplete(
      getHostFromWindow(),
      tenantAlias,
      query,
    ),
    keepPreviousData: true,
    staleTime: minutesToMilliseconds(1),
    select: (data) => data.parts,
    enabled: Boolean(query),
  });

  useEffect(() => {
    const partIDs = parts.map(({ id }) => id);

    /**
     * Request the prices for the autocomplete.
     */
    requestMultiplePartsPrices(partIDs);
  }, [getPartPrice, parts, requestMultiplePartsPrices]);

  const partsWithDiscounts = parts.map((part) => ({
    ...part,
    price: getPartPrice(part.id)?.price as number,
    originalPrice: getPartPrice(part.id)?.originalPrice as number,
  }));

  return { arePartsLoading, parts: partsWithDiscounts };
};
