import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
type FilterAccordionProps = {
  title: string;
  defaultExpanded?: boolean;
  children: React.ReactNode;
};
export const FilterAccordion = ({
  title,
  defaultExpanded = false,
  children
}: FilterAccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  return <Accordion disableGutters sx={{
    p: 0,
    mb: 1,
    borderWidth: 0,
    '&::before': {
      display: 'none'
    }
  }} expanded={isExpanded} onChange={() => setIsExpanded(prev => !prev)} data-sentry-element="Accordion" data-sentry-component="FilterAccordion" data-sentry-source-file="FilterAccordion.tsx">
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${title.toLowerCase()}-content`} id={`${title.toLowerCase()}-header`} data-sentry-element="AccordionSummary" data-sentry-source-file="FilterAccordion.tsx">
        {title}
      </AccordionSummary>
      <AccordionDetails sx={{
      p: 0,
      pb: 1,
      pl: 1.5,
      borderWidth: 0
    }} data-sentry-element="AccordionDetails" data-sentry-source-file="FilterAccordion.tsx">
        {children}
      </AccordionDetails>
    </Accordion>;
};