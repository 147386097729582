import { createContext, useContext, TransitionStartFunction } from 'react';

type NavigationTransitionContextType = {
  isPending: boolean;
  startTransition: TransitionStartFunction;
};

export const NavigationTransitionContext = createContext<
  NavigationTransitionContextType | undefined
>(undefined);

export const useNavigationTransitionContext = () => {
  const context = useContext(NavigationTransitionContext);
  if (context === undefined) {
    throw new Error(
      'useNavigationTransitionContext must be used within a NavigationTransitionContextProvider',
    );
  }
  return context;
};
