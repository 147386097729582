'use client';

import { useState } from 'react';
import { Chip } from '@mui/material';
import { useManufacturerParam } from '@/global/pages/Search/_hooks/useManufacturerParam';
import { SearchDataResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
type ManufacturerFacetChipFilterProps = {
  manufacturerFacet: SearchDataResponse['manufacturerFacets'][0];
};
export const ManufacturerFacetChipFilter = ({
  manufacturerFacet
}: ManufacturerFacetChipFilterProps) => {
  const {
    updateManufacturerParam,
    isChecked
  } = useManufacturerParam();
  const isActiveInitial = isChecked(manufacturerFacet);
  const [isActive, setIsActive] = useState(isActiveInitial);
  const onDelete = () => {
    if (!isActive) {
      return;
    }
    setIsActive(false);
    updateManufacturerParam(manufacturerFacet, false);
  };
  const onClick = () => {
    if (isActive) {
      return;
    }
    setIsActive(true);
    updateManufacturerParam(manufacturerFacet, true);
  };
  return <Chip color={isActive ? 'primary' : 'default'} key={manufacturerFacet.id} label={manufacturerFacet.name} size="medium" onDelete={isActive ? onDelete : undefined} onClick={onClick} data-sentry-element="Chip" data-sentry-component="ManufacturerFacetChipFilter" data-sentry-source-file="ManufacturerFacetChipMobileFilter.tsx" />;
};