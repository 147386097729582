import { forwardRef, RefObject } from 'react';
import { useKey } from 'react-use';
import { Box, BoxProps, Fade, Popper } from '@mui/material';
import { NoQuery } from '@qb/frontend/components/OmniSearch/_components/OmniSearchDesktopPopup/components/NoQuery';
import { Query } from '@qb/frontend/components/OmniSearch/_components/OmniSearchDesktopPopup/components/Query';
import { TenantAliasProps } from '@qb/frontend/types/tenantAlias.types';
import { OmniSearchBackdrop } from '../OmniSearchBackdrop';
type OmniSearchDesktopPopupProps = TenantAliasProps & {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  query: string;
  isUserTyping: boolean;
  marginTop?: BoxProps['marginTop'];
};
export const OmniSearchDesktopPopup = forwardRef<HTMLElement, OmniSearchDesktopPopupProps>(function Popup({
  query,
  isOpen,
  setIsOpen,
  marginTop,
  isUserTyping,
  tenantAlias
}, ref) {
  const currentRef = (ref as RefObject<HTMLElement>);
  useKey('Escape', () => {
    setIsOpen(false);
    if (!!document && document?.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  });
  return <>
      {isOpen && <OmniSearchBackdrop isOpen={isOpen} setIsOpen={setIsOpen} />}
      <Popper open={isOpen} anchorEl={currentRef?.current} transition placement="bottom-start" sx={{
      zIndex: 'var(--qb-omni-search-z-index, calc(var(--mui-zIndex-appBar) + 1))',
      minWidth: 700,
      width: currentRef?.current?.offsetWidth,
      mt: 1
    }} modifiers={[{
      name: 'flip',
      enabled: true,
      options: {
        // flips the popper to the opposite side of the anchor element when there is not enough space for it to be fully displayed.
        // @see https://popper.js.org/docs/v2/modifiers/flip/
        altBoundary: true,
        rootBoundary: 'document',
        padding: 8
      }
    }]}>
        {({
        TransitionProps
      }) => <Fade {...TransitionProps}>
            <Box sx={{
          height: '100%',
          mt: marginTop,
          mb: marginTop
        }}>
              {query ? <Query query={query} isUserTyping={isUserTyping} tenantAlias={tenantAlias} /> : <NoQuery tenantAlias={tenantAlias} />}
            </Box>
          </Fade>}
      </Popper>
    </>;
});