import InfoIcon from '@mui/icons-material/Info';
import { Box, Link, Stack, Tooltip, Typography } from '@mui/material';
import { ImageWithFallback } from '@qb/frontend/components/ImageWithFallback';
import { clampLinesSx } from '@qb/frontend/utils/clampLinesSx';
import { formatCents, getDiscountPercentage } from '@qb/frontend/utils/formatCurrency';
import { getPartMarketableStatus } from '@qb/frontend/utils/getPartMarketableStatus';
import { getConfigFromTenantAlias, TenantAlias } from '@/shared/tenants-alias-map';
import { SearchPartsResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
import { generatePartURL } from '@/shared/utils/URLs';
import { DeliveryChip } from './DeliveryChip';
import { QuickQuoteChip } from './QuickQuoteChip';
import { StockFoundChip } from './StockFoundChip';
const TRUNCATE_LENGTH = 80;
type PartResultCardMobileProps = {
  tenantAlias: TenantAlias;
  part: SearchPartsResponse['parts'][0];
};
export const PartResultCardMobile = ({
  tenantAlias,
  part
}: PartResultCardMobileProps) => {
  const discountPercentage = getDiscountPercentage(part.originalPrice, part.price);
  const isDiscounted = !!discountPercentage;
  const combinedStock = part.onHandQty + part.manufacturerStock;
  const tenantConfig = getConfigFromTenantAlias(tenantAlias);
  return <Box key={part.id} sx={{
    backgroundColor: 'common.backgroundColor',
    width: '100%',
    p: 3
  }} data-sentry-element="Box" data-sentry-component="PartResultCardMobile" data-sentry-source-file="PartResultCardMobile.tsx">
      <Stack direction="row" spacing={2} data-sentry-element="Stack" data-sentry-source-file="PartResultCardMobile.tsx">
        <ImageWithFallback tenantAlias={tenantAlias} src={part.images[0]?.target} width={50} height={50} alt={`${part.partNumber} on ${tenantConfig.companyName}`} data-sentry-element="ImageWithFallback" data-sentry-source-file="PartResultCardMobile.tsx" />
        <Box sx={{
        width: '100%'
      }} data-sentry-element="Box" data-sentry-source-file="PartResultCardMobile.tsx">
          <Stack direction="row" sx={{
          justifyContent: 'space-between',
          width: '100%',
          mb: 1
        }} data-sentry-element="Stack" data-sentry-source-file="PartResultCardMobile.tsx">
            <Link title={`View Part ${part.partNumber} Page`} href={generatePartURL(part.manufacturer.name, part.partNumber, part.id)} data-sentry-element="Link" data-sentry-source-file="PartResultCardMobile.tsx">
              <Typography component="span" variant="bodyMediumPrimary" sx={{
              color: 'primary.main'
            }} data-sentry-element="Typography" data-sentry-source-file="PartResultCardMobile.tsx">
                {part.partNumber}
              </Typography>
            </Link>
            {!!part.price && !isDiscounted && <Typography variant="h6" component="span" sx={{
            color: 'primary.main'
          }}>
                {formatCents(part.price)}
              </Typography>}
            {!!part.price && isDiscounted && <Stack>
                <Box sx={{
              bgcolor: 'accent.main',
              pl: 0.5,
              pt: 0.5,
              pb: 0.5,
              pr: 2,
              mr: 'auto',
              mb: 0.5,
              display: 'inline-block',
              borderRadius: '0 50px 50px 0'
            }}>
                  <Typography variant="bodySmallPrimary" sx={{
                fontWeight: 600,
                color: 'text.primary'
              }}>
                    {formatCents(part.price)}
                    {!!part.originalPrice && <Typography component="span" variant="bodySmall" sx={{
                  fontSize: 10,
                  fontWeight: 600,
                  display: 'inline-block',
                  textDecoration: 'line-through',
                  ml: 1,
                  position: 'relative',
                  top: '-1px'
                }}>
                        {formatCents(part.originalPrice)}
                      </Typography>}
                  </Typography>
                </Box>
                <Typography variant="bodySmallPrimary" sx={{
              color: 'text.primary',
              fontSize: 10,
              mt: 0
            }}>
                  Your special price{' '}
                  <Tooltip title="This price includes special discounts for your company.">
                    <InfoIcon sx={{
                  fontSize: 10,
                  cursor: 'pointer',
                  mb: '-2px'
                }} />
                  </Tooltip>
                </Typography>
              </Stack>}
          </Stack>
          <Stack direction="row" sx={{
          justifyContent: 'space-between',
          width: '100%',
          mb: 1
        }} data-sentry-element="Stack" data-sentry-source-file="PartResultCardMobile.tsx">
            <Typography variant="bodyMediumPrimary" component="span" data-sentry-element="Typography" data-sentry-source-file="PartResultCardMobile.tsx">
              {part.manufacturer.name}
            </Typography>
          </Stack>
          <Typography variant="bodyMedium" sx={[{
          mb: 1,
          width: 'fit-content'
        }, clampLinesSx(3)]} data-sentry-element="Typography" data-sentry-source-file="PartResultCardMobile.tsx">
            {part.description.length > TRUNCATE_LENGTH ? part.description.substr(0, TRUNCATE_LENGTH) + '...' : part.description}
          </Typography>
          <Stack sx={{
          alignItems: 'end'
        }} data-sentry-element="Stack" data-sentry-source-file="PartResultCardMobile.tsx">
            <Stack direction="row" spacing={1} data-sentry-element="Stack" data-sentry-source-file="PartResultCardMobile.tsx">
              {getPartMarketableStatus(part) === 'marketable' && <>
                  <StockFoundChip stock={combinedStock} />
                  <DeliveryChip shipProcessingDays={part.shipProcessingDays} />
                </>}
              {getPartMarketableStatus(part) === 'hasStockNoPrice' && <>
                  <StockFoundChip stock={combinedStock} />
                  <QuickQuoteChip />
                </>}
              {getPartMarketableStatus(part) === 'notMarketable' && <QuickQuoteChip />}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>;
};