'use client';

import { Chip } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { useManufacturerParam } from '@/global/pages/Search/_hooks/useManufacturerParam';
import { SearchDataResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
type ManufacturerFacetChipProps = {
  manufacturerFacet: SearchDataResponse['manufacturerFacets'][0];
};
export const ManufacturerFacetChip = ({
  manufacturerFacet
}: ManufacturerFacetChipProps) => {
  const isMobile = useIsMobile();
  const {
    updateManufacturerParam,
    isChecked
  } = useManufacturerParam();
  const isActive = isChecked(manufacturerFacet);
  if (!isActive) {
    return null;
  }
  const onDelete = () => {
    updateManufacturerParam(manufacturerFacet, false);
  };
  return <Chip color="primary" key={manufacturerFacet.id} label={manufacturerFacet.name} size={isMobile ? 'small' : 'medium'} onDelete={onDelete} data-sentry-element="Chip" data-sentry-component="ManufacturerFacetChip" data-sentry-source-file="ManufacturerFacetChip.tsx" />;
};