import { JSONObject } from '../../types/util/JSON';

export const toOptionalNumber = (
  obj: JSONObject,
  key: string,
): number | undefined => {
  if (!obj[key]) return undefined;
  if (typeof obj[key] === 'number') return obj[key] as number;
  throw new Error(`Property ${key} must be a string`);
};

export const requireString = (
  obj: JSONObject,
  key: string,
  skipValidation: boolean,
): string => {
  if (typeof obj[key] === 'string') return obj[key] as string;
  if (!obj[key]) return '';
  if (skipValidation) return '';
  throw new Error(`Property ${key} must be a string`);
};

export const requireNumber = (
  obj: JSONObject,
  key: string,
  skipValidation: boolean,
): number => {
  if (typeof obj[key] === 'number') return obj[key] as number;
  if (skipValidation) return 0;
  throw new Error(`Property ${key} must be a number`);
};
