import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Chip, Typography } from '@mui/material';
type StockFoundChipProps = {
  stock?: number;
};
export const StockFoundChip = ({
  stock
}: StockFoundChipProps) => {
  if (!stock) {
    return null;
  }
  return <Chip size="small" icon={<CheckCircleIcon sx={{
    fontSize: '17px',
    color: 'common.white'
  }} />} color="success" label={<Typography variant="bodySmall" sx={{
    color: 'success.contrastText'
  }}>
          {stock} in stock
        </Typography>} data-sentry-element="Chip" data-sentry-component="StockFoundChip" data-sentry-source-file="StockFoundChip.tsx" />;
};