'use client';

import { PropsWithChildren, Suspense } from 'react';
import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
export type SearchResultsProps = PropsWithChildren<{
  suspenseKey: string;
}>;

// This component is a workaround to make work Suspense trigger loading when the SearchParam change, which is not default behaviour.
// For this to work, the key prop must be passed to the Suspense component AND this Suspense component must be a client component.
// The component passed as children is still a server component.
// https://github.com/vercel/next.js/issues/53543
export const PartsResultsSuspense = (props: SearchResultsProps) => {
  return <Suspense fallback={<LoadingSkeleton />} key={props.suspenseKey} data-sentry-element="Suspense" data-sentry-component="PartsResultsSuspense" data-sentry-source-file="PartsResultsSuspense.tsx">
      {props.children}
    </Suspense>;
};
const LoadingSkeleton = () => {
  return <>
      <Skeleton variant="text" width="40%" height={32} sx={{
      mb: 1,
      mt: 1,
      ml: {
        xs: 2,
        md: 0
      }
    }} data-sentry-element="Skeleton" data-sentry-source-file="PartsResultsSuspense.tsx" />
      <TableContainer sx={{
      px: 0
    }} data-sentry-element="TableContainer" data-sentry-source-file="PartsResultsSuspense.tsx">
        <Table size="medium" data-sentry-element="Table" data-sentry-source-file="PartsResultsSuspense.tsx">
          <TableHead data-sentry-element="TableHead" data-sentry-source-file="PartsResultsSuspense.tsx">
            <TableRow data-sentry-element="TableRow" data-sentry-source-file="PartsResultsSuspense.tsx">
              {[...Array(6)].map((_, index) => <TableCell key={index}>
                  <Skeleton variant="text" width="100%" />
                </TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody data-sentry-element="TableBody" data-sentry-source-file="PartsResultsSuspense.tsx">
            {[...Array(10)].map((_, rowIndex) => <TableRow key={rowIndex}>
                <TableCell>
                  <Skeleton variant="rectangular" width={50} height={50} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width="30%" />
                  <Skeleton variant="text" width={130} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width="80%" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width="80%" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rounded" width={80} height={24} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rounded" width={80} height={24} />
                </TableCell>
              </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
    </>;
};