'use client';

import { Chip } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { useUrlSearchParam } from '@/global/pages/Search/_hooks/useUrlSearchParam';
import { SearchDataResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
type FacetChipProps = {
  facet: SearchDataResponse['facets'][0];
};
export const FacetChips = ({
  facet
}: FacetChipProps) => {
  const {
    searchParamValues,
    updateSearchParam,
    isPending
  } = useUrlSearchParam(facet.camelCaseName);
  const isMobile = useIsMobile();
  const optionsInParamsFiltered = facet.options.filter(option => searchParamValues.includes(option.value));
  return optionsInParamsFiltered.map(option => <Chip color="primary" key={option.value} label={option.value} size={isMobile ? 'small' : 'medium'} disabled={isPending} onDelete={() => {
    const newFacetValues = searchParamValues.filter(currentFacetValue => currentFacetValue !== option.value);
    updateSearchParam(newFacetValues);
  }} />);
};