'use client';

import { LoadingButton } from '@mui/lab';
import { Typography, Button, Stack } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { REQUEST_QUOTE_VALUE, CART_AND_BUY_VALUE, QUOTE_AND_CART_VALUE, DEFAULT_VALUE } from '@/shared/models/SPPartPageSettingConstants';
import { PartDisplayDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
type CallToActionRequestQuoteProps = {
  part: PartDisplayDataResponse['part'];
  spPartPageSetting: PartDisplayDataResponse['spPartPageSetting'];
  qty: number;
  price: number | null;
  shouldUseMessageFromSettings: boolean;
};
const CallToActionRequestQuoteButtons = ({
  qty,
  part,
  spPartPageSetting,
  price
}: CallToActionRequestQuoteProps) => {
  const isMobile = useIsMobile();
  // If price, Fallback to DEFAULT_VALUE when the setting from the API is not available
  const value = price ? spPartPageSetting?.value || DEFAULT_VALUE : REQUEST_QUOTE_VALUE;
  const requestQuoteButton = <Button fullWidth variant={value === REQUEST_QUOTE_VALUE ? 'contained' : 'outlined'} color={value === REQUEST_QUOTE_VALUE ? 'secondary' : 'primary'} onClick={() => {
    window?.salesPilotPostMessages?.createQuote({
      qty,
      partID: part.id
    });
  }}>
      Request Quote
    </Button>;
  const buyNowButton = <Button fullWidth variant="contained" color="primary" onClick={() => {
    window?.salesPilotPostMessages?.buyNow({
      qty,
      partID: part.id
    });
  }}>
      Buy now
    </Button>;
  const addToCartButton = <LoadingButton fullWidth variant="contained" color="secondary" onClick={() => {
    window?.salesPilotPostMessages?.addToCart({
      qty,
      partID: part.id
    });
  }}>
      Add to cart
    </LoadingButton>;
  if (value === REQUEST_QUOTE_VALUE) {
    return <Stack direction={isMobile ? 'row' : 'column'} sx={{
      gap: 1
    }}>
        {requestQuoteButton}
      </Stack>;
  }
  if (value === CART_AND_BUY_VALUE) {
    return <Stack direction={isMobile ? 'row' : 'column'} sx={{
      gap: 1
    }}>
        {addToCartButton}
        {buyNowButton}
      </Stack>;
  }
  if (value === QUOTE_AND_CART_VALUE) {
    return <Stack direction={isMobile ? 'row' : 'column'} sx={{
      gap: 1
    }}>
        {addToCartButton}
        {requestQuoteButton}
      </Stack>;
  }
  return null;
};
export const CallToActionRequestQuote = (props: CallToActionRequestQuoteProps) => {
  const isMobile = useIsMobile();
  const renderSecondaryMessage = () => {
    // No secondary message on mobile
    if (isMobile) {
      return null;
    }
    // if available and applicable, Use the message from the settings.
    if (props.shouldUseMessageFromSettings && !!props.spPartPageSetting?.secondaryMessage) {
      return <Typography variant="bodyMedium" sx={{
        mt: 2
      }}>
          {props.spPartPageSetting?.secondaryMessage}
        </Typography>;
    }

    // Otherwise, use the default message
    return <Typography variant="bodyLarge" align="center" sx={{
      mt: 2
    }} data-sentry-element="Typography" data-sentry-component="renderSecondaryMessage" data-sentry-source-file="CallToActionRequestQuote.tsx">
        Average response time:{' '}
        <Typography variant="bodyLargePrimary" component="span" sx={{
        color: 'success.main'
      }} data-sentry-element="Typography" data-sentry-source-file="CallToActionRequestQuote.tsx">
          11 minutes
        </Typography>
      </Typography>;
  };
  return <>
      <CallToActionRequestQuoteButtons {...props} data-sentry-element="CallToActionRequestQuoteButtons" data-sentry-source-file="CallToActionRequestQuote.tsx" />
      {renderSecondaryMessage()}
    </>;
};