import { PropsWithChildren } from 'react';
import { Box, SxProps } from '@mui/material';
type ShowOnBreakpointProps = PropsWithChildren<{
  breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  sx?: SxProps;
}>;
export const ShowOnBreakpointUp = ({
  children,
  breakpoint,
  sx
}: ShowOnBreakpointProps) => <Box sx={[{
  display: {
    xs: 'none',
    [breakpoint]: 'block'
  }
}, ...(Array.isArray(sx) ? sx : [sx])]} data-sentry-element="Box" data-sentry-component="ShowOnBreakpointUp" data-sentry-source-file="ShowOnBreakpoint.tsx">
    {children}
  </Box>;
export const ShowOnBreakpointDown = ({
  children,
  breakpoint,
  sx
}: ShowOnBreakpointProps) => <Box sx={[{
  display: {
    xs: 'block',
    [breakpoint]: 'none'
  }
}, ...(Array.isArray(sx) ? sx : [sx])]} data-sentry-element="Box" data-sentry-component="ShowOnBreakpointDown" data-sentry-source-file="ShowOnBreakpoint.tsx">
    {children}
  </Box>;