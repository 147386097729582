type Part = {
  onHandQty: number;
  manufacturerStock: number;
  price?: number;
};

type PartMarketableStatus = 'marketable' | 'hasStockNoPrice' | 'notMarketable';
export const getPartMarketableStatus = (part: Part): PartMarketableStatus => {
  if ((part.onHandQty || part.manufacturerStock) && part.price) {
    return 'marketable';
  }
  if ((part.onHandQty || part.manufacturerStock) && !part.price) {
    return 'hasStockNoPrice';
  }
  return 'notMarketable';
};
