'use client';

import { useEffect } from 'react';
import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useRequestPartPriceFromSPW } from '@/hooks/useRequestPartPriceFromSPW';
import { TenantAlias } from '@/shared/tenants-alias-map';
import { SearchPartsResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
import { PartResultCardMobile } from './PartResultCardMobile';
import { SearchResultRow } from './SearchResultRow';

// hardcoded now for popular parts
const SHIP_PROCESSING_DAYS = 0;
type SearchResultsTableProps = {
  tenantAlias: TenantAlias;
  parts: SearchPartsResponse['parts'];
};
export const SearchResultsTable = ({
  tenantAlias,
  parts
}: SearchResultsTableProps) => {
  const {
    requestMultiplePartsPrices,
    getPartPrice
  } = useRequestPartPriceFromSPW();
  useEffect(() => {
    const partIDs = parts.map(({
      id
    }) => id);

    /**
     * Request the prices for the popular products.
     */
    requestMultiplePartsPrices(partIDs);
  }, [getPartPrice, parts, requestMultiplePartsPrices]);
  const partsWithDiscounts = parts.map(part => ({
    ...part,
    ...getPartPrice(part.id)
  }));
  return <>
      <Stack spacing={2} sx={{
      display: {
        xs: 'block',
        sm: 'none'
      }
    }} data-sentry-element="Stack" data-sentry-source-file="SearchResultsTable.tsx">
        {partsWithDiscounts.map(part => <PartResultCardMobile key={part.id} tenantAlias={tenantAlias} part={{
        ...part,
        price: part.price ?? undefined,
        originalPrice: part.originalPrice ?? undefined,
        shipProcessingDays: SHIP_PROCESSING_DAYS
      }} />)}
      </Stack>
      <TableContainer sx={{
      px: 0,
      display: {
        xs: 'none',
        sm: 'block'
      }
    }} data-sentry-element="TableContainer" data-sentry-source-file="SearchResultsTable.tsx">
        <Table size="medium" sx={{
        '& td, th': {
          border: 0
        },
        '& th': {
          backgroundColor: 'tertiary.dark',
          color: 'tertiary.contrastText',
          py: 1
        },
        '& td': {
          borderBottom: '10px solid white'
        }
      }} data-sentry-element="Table" data-sentry-source-file="SearchResultsTable.tsx">
          <TableHead data-sentry-element="TableHead" data-sentry-source-file="SearchResultsTable.tsx">
            <TableRow data-sentry-element="TableRow" data-sentry-source-file="SearchResultsTable.tsx">
              <TableCell colSpan={2} sx={{
              width: {
                md: '35%',
                lg: '45%',
                xl: '55%'
              },
              minWidth: 300
            }} data-sentry-element="TableCell" data-sentry-source-file="SearchResultsTable.tsx">
                Item
              </TableCell>
              <TableCell sx={{
              px: 1
            }} data-sentry-element="TableCell" data-sentry-source-file="SearchResultsTable.tsx">Manufacturer</TableCell>
              <TableCell sx={{
              px: 1
            }} data-sentry-element="TableCell" data-sentry-source-file="SearchResultsTable.tsx">Price</TableCell>
              <TableCell sx={{
              px: 1
            }} data-sentry-element="TableCell" data-sentry-source-file="SearchResultsTable.tsx">Stock</TableCell>
              <TableCell sx={{
              px: 1
            }} data-sentry-element="TableCell" data-sentry-source-file="SearchResultsTable.tsx">Delivery</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-sentry-element="TableBody" data-sentry-source-file="SearchResultsTable.tsx">
            {partsWithDiscounts.map(part => <SearchResultRow key={part.id} tenantAlias={tenantAlias} part={{
            ...part,
            price: part.price ?? undefined,
            originalPrice: part.originalPrice ?? undefined,
            shipProcessingDays: SHIP_PROCESSING_DAYS
          }} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </>;
};