import { ElementType } from 'react';
import { JSONObject } from '../../types/util/JSON';
import { ParamsParent } from './ParamsParent';
import { requireString } from './ParamsParserHelper';

export type PartDisplayParamsDrawerType = {
  title: string;
  subtitle: string;
  icon: string | ElementType;
  type: string;
  insidePhoto: string;
  insideText: string;
  faqTitle1: string;
  faqSubtitle1: string;
  returnPolicyURL: string;
  faqTitle2: string;
  faqSubtitle2: string;
};

export class PartDisplayParams extends ParamsParent {
  private static componentCode = 1000;

  public static drawerTypes = {
    shipping: 'Shipping',
    warranty: 'Warranty',
    payment: 'Payment Methods',
    customerService: 'Customer Service',
    emptyDrawer: 'Empty Drawer',
  };

  public static drawerTypesOptions = [
    PartDisplayParams.drawerTypes.shipping,
    PartDisplayParams.drawerTypes.warranty,
    PartDisplayParams.drawerTypes.payment,
    PartDisplayParams.drawerTypes.customerService,
    PartDisplayParams.drawerTypes.emptyDrawer,
  ];

  public returnPolicyURL: string; // Current logic is to use it for the Payment Drawer only.

  // Drawer 1
  public drawer1Title: string;
  public drawer1Subtitle: string;
  public drawer1Icon: string;
  public drawer1Type: string;
  public drawer1InsidePhoto: string;
  public drawer1InsideText: string;
  public drawer1FaqTitle1: string;
  public drawer1FaqSubtitle1: string;
  public drawer1FaqTitle2: string;
  public drawer1FaqSubtitle2: string;

  // Drawer 2
  public drawer2Title: string;
  public drawer2Subtitle: string;
  public drawer2Icon: string;
  public drawer2Type: string;
  public drawer2InsidePhoto: string;
  public drawer2InsideText: string;
  public drawer2FaqTitle1: string;
  public drawer2FaqSubtitle1: string;
  public drawer2FaqTitle2: string;
  public drawer2FaqSubtitle2: string;

  // Drawer 3
  public drawer3Title: string;
  public drawer3Subtitle: string;
  public drawer3Icon: string;
  public drawer3Type: string;
  public drawer3InsidePhoto: string;
  public drawer3InsideText: string;
  public drawer3FaqTitle1: string;
  public drawer3FaqSubtitle1: string;
  public drawer3FaqTitle2: string;
  public drawer3FaqSubtitle2: string;

  // Drawer 4
  public drawer4Title: string;
  public drawer4Subtitle: string;
  public drawer4Icon: string;
  public drawer4Type: string;
  public drawer4InsidePhoto: string;
  public drawer4InsideText: string;
  public drawer4FaqTitle1: string;
  public drawer4FaqSubtitle1: string;
  public drawer4FaqTitle2: string;
  public drawer4FaqSubtitle2: string;

  // Drawer 5
  public drawer5Title: string;
  public drawer5Subtitle: string;
  public drawer5Icon: string;
  public drawer5Type: string;
  public drawer5InsidePhoto: string;
  public drawer5InsideText: string;
  public drawer5FaqTitle1: string;
  public drawer5FaqSubtitle1: string;
  public drawer5FaqTitle2: string;
  public drawer5FaqSubtitle2: string;

  constructor(params: JSONObject, skipValidation = false) {
    super(PartDisplayParams.componentCode, [
      { name: 'returnPolicyURL', type: 'shortText' },
      // Drawer 1
      { name: 'drawer1Title', type: 'shortText' },
      { name: 'drawer1Subtitle', type: 'shortText' },
      { name: 'drawer1Icon', type: 'url', subtype: 'image' },
      {
        name: 'drawer1Type',
        type: 'select',
        options: PartDisplayParams.drawerTypesOptions,
      },
      { name: 'drawer1InsidePhoto', type: 'url', subtype: 'image' },
      { name: 'drawer1InsideText', type: 'longText' },
      { name: 'drawer1FaqTitle1', type: 'shortText' },
      { name: 'drawer1FaqSubtitle1', type: 'shortText' },
      { name: 'drawer1FaqTitle2', type: 'shortText' },
      { name: 'drawer1FaqSubtitle2', type: 'shortText' },
      // Drawer 2
      { name: 'drawer2Title', type: 'shortText' },
      { name: 'drawer2Subtitle', type: 'shortText' },
      { name: 'drawer2Icon', type: 'url', subtype: 'image' },
      {
        name: 'drawer2Type',
        type: 'select',
        options: PartDisplayParams.drawerTypesOptions,
      },
      { name: 'drawer2InsidePhoto', type: 'url', subtype: 'image' },
      { name: 'drawer2InsideText', type: 'longText' },
      { name: 'drawer2FaqTitle1', type: 'shortText' },
      { name: 'drawer2FaqSubtitle1', type: 'shortText' },
      { name: 'drawer2FaqTitle2', type: 'shortText' },
      { name: 'drawer2FaqSubtitle2', type: 'shortText' },
      // Drawer 3
      { name: 'drawer3Title', type: 'shortText' },
      { name: 'drawer3Subtitle', type: 'shortText' },
      { name: 'drawer3Icon', type: 'url', subtype: 'image' },
      {
        name: 'drawer3Type',
        type: 'select',
        options: PartDisplayParams.drawerTypesOptions,
      },
      { name: 'drawer3InsidePhoto', type: 'url', subtype: 'image' },
      { name: 'drawer3InsideText', type: 'longText' },
      { name: 'drawer3FaqTitle1', type: 'shortText' },
      { name: 'drawer3FaqSubtitle1', type: 'shortText' },
      { name: 'drawer3FaqTitle2', type: 'shortText' },
      { name: 'drawer3FaqSubtitle2', type: 'shortText' },
      // Drawer 4
      { name: 'drawer4Title', type: 'shortText' },
      { name: 'drawer4Subtitle', type: 'shortText' },
      { name: 'drawer4Icon', type: 'url', subtype: 'image' },
      {
        name: 'drawer4Type',
        type: 'select',
        options: PartDisplayParams.drawerTypesOptions,
      },
      { name: 'drawer4InsidePhoto', type: 'url', subtype: 'image' },
      { name: 'drawer4InsideText', type: 'longText' },
      { name: 'drawer4FaqTitle1', type: 'shortText' },
      { name: 'drawer4FaqSubtitle1', type: 'shortText' },
      { name: 'drawer4FaqTitle2', type: 'shortText' },
      { name: 'drawer4FaqSubtitle2', type: 'shortText' },
      // Drawer 5
      { name: 'drawer5Title', type: 'shortText' },
      { name: 'drawer5Subtitle', type: 'shortText' },
      { name: 'drawer5Icon', type: 'url', subtype: 'image' },
      {
        name: 'drawer5Type',
        type: 'select',
        options: PartDisplayParams.drawerTypesOptions,
      },
      { name: 'drawer5InsidePhoto', type: 'url', subtype: 'image' },
      { name: 'drawer5InsideText', type: 'longText' },
      { name: 'drawer5FaqTitle1', type: 'shortText' },
      { name: 'drawer5FaqSubtitle1', type: 'shortText' },
      { name: 'drawer5FaqTitle2', type: 'shortText' },
      { name: 'drawer5FaqSubtitle2', type: 'shortText' },
    ]);

    this.returnPolicyURL = requireString(
      params,
      'returnPolicyURL',
      skipValidation,
    );

    // Drawer 1
    this.drawer1Title = requireString(params, 'drawer1Title', skipValidation);
    this.drawer1Subtitle = requireString(
      params,
      'drawer1Subtitle',
      skipValidation,
    );
    this.drawer1Icon = requireString(params, 'drawer1Icon', skipValidation);
    this.drawer1Type = requireString(params, 'drawer1Type', skipValidation);
    this.drawer1InsidePhoto = requireString(
      params,
      'drawer1InsidePhoto',
      skipValidation,
    );

    this.drawer1InsideText = requireString(
      params,
      'drawer1InsideText',
      skipValidation,
    );

    this.drawer1FaqTitle1 = requireString(
      params,
      'drawer1FaqTitle1',
      skipValidation,
    );
    this.drawer1FaqSubtitle1 = requireString(
      params,
      'drawer1FaqSubtitle1',
      skipValidation,
    );
    this.drawer1FaqTitle2 = requireString(
      params,
      'drawer1FaqTitle2',
      skipValidation,
    );

    this.drawer1FaqSubtitle2 = requireString(
      params,
      'drawer1FaqSubtitle2',
      skipValidation,
    );
    // Drawer 2
    this.drawer2Title = requireString(params, 'drawer2Title', skipValidation);
    this.drawer2Subtitle = requireString(
      params,
      'drawer2Subtitle',
      skipValidation,
    );
    this.drawer2Icon = requireString(params, 'drawer2Icon', skipValidation);
    this.drawer2Type = requireString(params, 'drawer2Type', skipValidation);
    this.drawer2InsidePhoto = requireString(
      params,
      'drawer2InsidePhoto',
      skipValidation,
    );

    this.drawer2InsideText = requireString(
      params,
      'drawer2InsideText',
      skipValidation,
    );

    this.drawer2FaqTitle1 = requireString(
      params,
      'drawer2FaqTitle1',
      skipValidation,
    );
    this.drawer2FaqSubtitle1 = requireString(
      params,
      'drawer2FaqSubtitle1',
      skipValidation,
    );
    this.drawer2FaqTitle2 = requireString(
      params,
      'drawer2FaqTitle2',
      skipValidation,
    );

    this.drawer2FaqSubtitle2 = requireString(
      params,
      'drawer2FaqSubtitle2',
      skipValidation,
    );
    // Drawer 3
    this.drawer3Title = requireString(params, 'drawer3Title', skipValidation);
    this.drawer3Subtitle = requireString(
      params,
      'drawer3Subtitle',
      skipValidation,
    );
    this.drawer3Icon = requireString(params, 'drawer3Icon', skipValidation);
    this.drawer3Type = requireString(params, 'drawer3Type', skipValidation);
    this.drawer3InsidePhoto = requireString(
      params,
      'drawer3InsidePhoto',
      skipValidation,
    );

    this.drawer3InsideText = requireString(
      params,
      'drawer3InsideText',
      skipValidation,
    );

    this.drawer3FaqTitle1 = requireString(
      params,
      'drawer3FaqTitle1',
      skipValidation,
    );
    this.drawer3FaqSubtitle1 = requireString(
      params,
      'drawer3FaqSubtitle1',
      skipValidation,
    );
    this.drawer3FaqTitle2 = requireString(
      params,
      'drawer3FaqTitle2',
      skipValidation,
    );

    this.drawer3FaqSubtitle2 = requireString(
      params,
      'drawer3FaqSubtitle2',
      skipValidation,
    );
    // Drawer 4
    this.drawer4Title = requireString(params, 'drawer4Title', skipValidation);
    this.drawer4Subtitle = requireString(
      params,
      'drawer4Subtitle',
      skipValidation,
    );
    this.drawer4Icon = requireString(params, 'drawer4Icon', skipValidation);
    this.drawer4Type = requireString(params, 'drawer4Type', skipValidation);
    this.drawer4InsidePhoto = requireString(
      params,
      'drawer4InsidePhoto',
      skipValidation,
    );

    this.drawer4InsideText = requireString(
      params,
      'drawer4InsideText',
      skipValidation,
    );

    this.drawer4FaqTitle1 = requireString(
      params,
      'drawer4FaqTitle1',
      skipValidation,
    );
    this.drawer4FaqSubtitle1 = requireString(
      params,
      'drawer4FaqSubtitle1',
      skipValidation,
    );
    this.drawer4FaqTitle2 = requireString(
      params,
      'drawer4FaqTitle2',
      skipValidation,
    );

    this.drawer4FaqSubtitle2 = requireString(
      params,
      'drawer4FaqSubtitle2',
      skipValidation,
    );
    // Drawer 5
    this.drawer5Title = requireString(params, 'drawer5Title', skipValidation);
    this.drawer5Subtitle = requireString(
      params,
      'drawer5Subtitle',
      skipValidation,
    );
    this.drawer5Icon = requireString(params, 'drawer5Icon', skipValidation);
    this.drawer5Type = requireString(params, 'drawer5Type', skipValidation);
    this.drawer5InsidePhoto = requireString(
      params,
      'drawer5InsidePhoto',
      skipValidation,
    );

    this.drawer5InsideText = requireString(
      params,
      'drawer5InsideText',
      skipValidation,
    );

    this.drawer5FaqTitle1 = requireString(
      params,
      'drawer5FaqTitle1',
      skipValidation,
    );
    this.drawer5FaqSubtitle1 = requireString(
      params,
      'drawer5FaqSubtitle1',
      skipValidation,
    );
    this.drawer5FaqTitle2 = requireString(
      params,
      'drawer5FaqTitle2',
      skipValidation,
    );

    this.drawer5FaqSubtitle2 = requireString(
      params,
      'drawer5FaqSubtitle2',
      skipValidation,
    );
  }
}
