import { useCallback } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useNavigationTransitionContext } from '@/global/pages/Search/_contexts/NavigationTransitionContext';

export const createNewSearchParams = (
  searchParams: URLSearchParams | null,
  paramName: string,
  // Empty array here will delete the param
  values: string[],
) => {
  const newParams = new URLSearchParams(searchParams?.toString() || '');
  newParams.delete(paramName);
  values.forEach((value) => newParams.append(paramName, value));
  return newParams;
};

export const useUrlSearchParam = (paramName: string) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const searchParamValue = searchParams?.get(paramName);
  const searchParamValues = searchParams?.getAll(paramName) || [];
  const { startTransition, isPending } = useNavigationTransitionContext();

  const updateSearchParam = useCallback(
    (value: string | string[]) => {
      const newParams = createNewSearchParams(
        searchParams,
        paramName,
        Array.isArray(value) ? value : [value],
      );

      // We want to reset the page to 1 when a new filter is applied
      newParams.delete('page');

      startTransition(() =>
        router.push(`${pathname}?${newParams.toString()}`, { scroll: false }),
      );
    },
    [paramName, searchParams, router, pathname, startTransition],
  );

  const deleteSearchParam = useCallback(() => {
    const newParams = createNewSearchParams(searchParams, paramName, []);

    // We want to reset the page to 1 when a filter is removed
    newParams.delete('page');

    startTransition(() =>
      router.push(`${pathname}?${newParams.toString()}`, { scroll: false }),
    );
  }, [paramName, searchParams, router, pathname, startTransition]);

  return {
    updateSearchParam,
    deleteSearchParam,
    searchParamValue,
    searchParamValues,
    isPending,
  };
};
