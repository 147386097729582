import { useQuery } from '@tanstack/react-query';
import { hoursToMilliseconds, minutesToMilliseconds } from 'date-fns';
import { getHostFromWindow } from '@qb/frontend/utils/hosts/getHostFromWindow';
import { spsClientQueries } from '@qb/httpRequest/spsClientQueries';
import { TenantAlias } from '@/shared/tenants-alias-map';

const SEARCH_CATEGORIES_LIMIT = 15;

export const useSearchCategoriesRequest = (tenantAlias: TenantAlias) => {
  const { data: searchCategories, isLoading: areSearchCategoriesLoading } =
    useQuery({
      ...spsClientQueries.partCategory.searchCategories(
        getHostFromWindow(),
        tenantAlias,
      ),
      staleTime: minutesToMilliseconds(10),
      cacheTime: hoursToMilliseconds(1),
      select: (data) => data.slice(0, SEARCH_CATEGORIES_LIMIT),
    });

  return {
    searchCategories,
    areSearchCategoriesLoading,
  };
};
