'use client';

import ChevronRight from '@mui/icons-material/ChevronRight';
import { Button } from '@mui/material';
export const AllCategoriesButton = () => <Button href="/search/category" variant="contained" endIcon={<ChevronRight />} size="large" sx={theme => ({
  gridColumn: {
    xs: 'span 2',
    md: 'span 1'
  },
  mt: {
    xs: 2,
    md: 0
  },
  height: {
    md: '100%'
  },
  fontSize: {
    md: 20
  },
  flexWrap: 'wrap',
  textAlign: 'center',
  boxShadow: theme.shadows[4],
  '&:hover': {
    boxShadow: theme.shadows[4]
  }
})} color="primary" data-sentry-element="Button" data-sentry-component="AllCategoriesButton" data-sentry-source-file="AllCategoriesButton.tsx">
    All categories
  </Button>;