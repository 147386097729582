'use client';

import { useCallback, useState } from 'react';
import { FormControlLabel, Switch, SxProps, Typography } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { useUrlSearchParam } from '@/global/pages/Search/_hooks/useUrlSearchParam';
type InStockFilterProps = {
  sx?: SxProps;
};
export const InStockFilter = ({
  sx
}: InStockFilterProps) => {
  const {
    updateSearchParam,
    deleteSearchParam,
    searchParamValue,
    isPending
  } = useUrlSearchParam('inStockFilter');
  const isMobile = useIsMobile();
  const isInitialChecked = searchParamValue === 'true';
  const [isChecked, setIsChecked] = useState(isInitialChecked);
  const handleInStockChange = useCallback((checked: boolean) => {
    if (checked) {
      setIsChecked(true);
      updateSearchParam('true');
      return;
    }
    setIsChecked(false);
    deleteSearchParam();
  }, [updateSearchParam, deleteSearchParam]);
  return <FormControlLabel control={<Switch />} checked={isChecked} sx={sx} onChange={(_e, checked) => handleInStockChange(checked)} label={<Typography variant="bodySmall" noWrap sx={{
    color: 'text.primary'
  }}>
          {isMobile ? 'In Stock' : 'Show in stock only'}
        </Typography>} disabled={isPending} data-sentry-element="FormControlLabel" data-sentry-component="InStockFilter" data-sentry-source-file="InStockFilter.tsx" />;
};