'use client';

import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { Box, Pagination, PaginationItem } from '@mui/material';
import { createNewSearchParams } from '@/global/pages/Search/_hooks/useUrlSearchParam';
type SearchPartsPaginationProps = {
  page?: number;
  pagesCount: number;
};
export const SearchPartsPagination = ({
  page,
  pagesCount
}: SearchPartsPaginationProps) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  return <Box sx={{
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    my: 4
  }} data-sentry-element="Box" data-sentry-component="SearchPartsPagination" data-sentry-source-file="SearchPartsPagination.tsx">
      <Pagination page={(page ?? 0) + 1} count={pagesCount} renderItem={item => {
      const newPage = item?.page && item.page !== 1 ? [item.page.toString()] : [];
      const href = `${pathname}?${createNewSearchParams(searchParams, 'page', newPage).toString()}`;
      return <PaginationItem component={Link} href={href} {...item} />;
    }} data-sentry-element="Pagination" data-sentry-source-file="SearchPartsPagination.tsx" />
    </Box>;
};