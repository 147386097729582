'use client';

import { Chip, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as IconParcel } from '@qb/frontend/icons/icon-parcel.svg';
type DeliveryChipProps = {
  shipProcessingDays?: number;
};
export const DeliveryChip = ({
  shipProcessingDays
}: DeliveryChipProps) => {
  if (shipProcessingDays === undefined) {
    return null;
  }
  const message = getMessage(shipProcessingDays);
  const color = getColor(shipProcessingDays);
  return <Chip size="small" icon={<SvgIcon component={IconParcel} inheritViewBox sx={{
    fontSize: '17px',
    color: 'common.white'
  }} />} color={color} label={<Typography variant="bodySmall" sx={{
    color: 'success.contrastText'
  }}>
          {message}
        </Typography>} data-sentry-element="Chip" data-sentry-component="DeliveryChip" data-sentry-source-file="DeliveryChip.tsx" />;
};
const getMessage = (shipProcessingDays: number) => {
  if (shipProcessingDays <= 1) {
    return '24 hours';
  }
  if (shipProcessingDays <= 3) {
    return '2-3 days';
  }
  if (shipProcessingDays <= 7) {
    return '1 week';
  }
  return `${Math.ceil(shipProcessingDays / 7)} weeks`;
};
const getColor = (shipProcessingDays: number) => {
  if (shipProcessingDays <= 3) {
    return 'success';
  }
  if (shipProcessingDays <= 28) {
    return 'secondary';
  }
  return 'info';
};